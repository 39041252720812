import React, {useContext} from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <Link href="/">Home</Link>
      <Link href="/#about">About Us</Link>
      <Link href="/listings/search">Property Search</Link>
      <Link href="#contact">Contact Us</Link>
    </Wrapper>
  )

};

export default NavbarLinks;
