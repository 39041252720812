import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { Container } from '../../../common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper, Brand } from './styles';

const Navbar = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper as={Container} style={{ borderBottom: "3px solid #57a2b9" }}>
      <Brand as={Link} to="/" theme={theme}>
        <img width="100px" src={process.env.GATSBY_NAV_LOGO} alt={process.env.GATBSY_COMPANY_NAME} />
      </Brand>
      <NavbarLinks desktop />
    </Wrapper>
  );
};

export default Navbar;
